<template>
    <path d="m196.7 363.3-13.9-41.6c-3.3-9.8-12.4-16.4-22.8-16.4s-19.5 6.6-22.8 16.4l-18.2 54.7c-1.5 4.6-5.8 7.6-10.6 7.6h-12.4c-8.8 0-16 7.2-16 16s7.2 16 16 16h12.4c18.6 0 35.1-11.9 41-29.5l10.6-31.9 16.8 50.5c2.1 6.1 7.6 10.4 14.1 10.9h1.1c6 0 11.6-3.4 14.3-8.9l7.7-15.3c2.8-5.6 7.9-6.2 10-6.2s7.3 0.6 10.2 6.5c7.4 14.7 22.2 23.8 38.6 23.8h15.2c8.8 0 16-7.2 16-16s-7.2-16-16-16h-15.2c-4.3 0-8.1-2.4-10.2-6.5-11.9-23.8-46.2-30.2-65.9-14.1zm173.1-265.3-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-204c-26.5 0.1-48 21.6-48 48.1v415.9c0 26.5 21.5 48 48 48h287.9c26.5 0 48.1-21.5 48.1-48v-332c0-12.7-5.2-25-14.2-34zm-113.9-46 76.1 76.1h-76.1v-76.1zm80.1 412h-288v-415.9h160v104c0 13.3 10.7 24 24 24h104v287.9zm-248-352h80c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 64h80c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 384, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
